<template>
  <div class="myconfig">
    <SystemMenu />
    <!-- 筛选项 -->
    <div class="wrap">
      <span style="color: #e6a23c; font-size: 14px"
        ><i class="el-icon-warning"></i>
        保存修改个人资料成功后将自动退出，需重新登录。</span
      >
    </div>

    <!-- 功能按钮 -->
    <div class="wrap-blank">
      <el-button
        size="small"
        plain
        type="primary"
        icon="el-icon-check"
        @click="saveMyUserData"
        >保存</el-button
      >
    </div>

    <!-- 数据列表 -->
    <div class="wrap">
      <el-descriptions
        title="个人资料"
        labelClassName="labelClassName"
        :column="1"
        border
      >
        <el-descriptions-item label="用户名">
          <el-input
            v-model="userFormData.accountName"
            placeholder="请输入内容"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="用户角色">
          <el-radio v-model="userFormData.role" label="1" disabled
            >超级管理员</el-radio
          >
          <el-radio v-model="userFormData.role" label="2">管理员</el-radio>
          <el-radio v-model="userFormData.role" label="3">普通用户</el-radio>
        </el-descriptions-item>
        <el-descriptions-item label="手机号">
          <el-input
            v-model="userFormData.phone"
            placeholder="请输入内容"
            clearable
          ></el-input>
        </el-descriptions-item>
      </el-descriptions>

      <div style="height: 20px"></div>

      <el-descriptions
        title="登录密码"
        labelClassName="labelClassName"
        :column="1"
        border
      >
        <el-descriptions-item label="密码">
          <el-input
            v-model="userFormData.password"
            placeholder="请输入内容"
            show-password
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="确认密码">
          <el-input
            v-model="userFormData.repassword"
            placeholder="请输入内容"
            show-password
            clearable
          ></el-input>
        </el-descriptions-item>
      </el-descriptions>
      <div style="margin-top: 6px">如无需修改密码此项请留空</div>
    </div>
  </div>
</template>

<style lang="less" scope>
.myconfig {
  .el-form-item {
    margin-bottom: 0;
  }
  .unit-item {
    margin-bottom: 20px;
    border-bottom: 1px dashed #d5d9e4;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .device-record {
    width: 1200px;
    background-color: #f0f0f0;
  }
  .video-player {
    width: 1200px;
  }
  .my-label {
    width: 20%;
  }
  .my-content {
    width: 30%;
  }
  .el-date-editor {
    width: 100% !important;
  }
  .labelClassName {
    width: 120px;
  }
}
</style>

<script>
import SystemMenu from "@/components/SystemMenu.vue";

export default {
  name: "MyConfig",
  components: {
    SystemMenu,
  },
  data() {
    return {
      userId: localStorage.getItem("id"),
      userFormData: {
        userId: null,
        accountName: null,
        role: null,
        password: null,
        repassword: null,
        phone: null,
      },
    };
  },
  computed: {},
  mounted() {
    this.getMyUserData();
  },
  watch: {},
  methods: {
    getMyUserData: function () {
      var that = this;
      var data = {
        userId: this.userId,
      };
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/user/getById",
          data: JSON.stringify(data),
        })
        .then((res) => {
          console.log("getMyUserData =>", res);
          this.userFormData.userId = res.data.sysUser.userId;
          this.userFormData.accountName = res.data.sysUser.username;
          this.userFormData.role = res.data.roles[0] + "";
          this.userFormData.phone = res.data.sysUser.phone;
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    saveMyUserData: function () {
      var that = this;
      if (!that.userFormData.accountName) {
        that.$message("请填写用户名");
        return;
      }
      if (!that.userFormData.role) {
        that.$message("请选择用户角色");
        return;
      }
      if (!that.userFormData.phone) {
        that.$message("请填写用户电话");
        return;
      }
      if (that.userFormData.password != that.userFormData.repassword) {
        that.$message("确认密码不一致");
        return;
      }

      var data = {
        userId: that.userFormData.userId,
        username: that.userFormData.accountName,
        role: [that.userFormData.role],
        phone: that.userFormData.phone,
      };
      if (
        that.userFormData.password &&
        that.userFormData.password == that.userFormData.repassword
      ) {
        data.password = that.userFormData.password;
      }
      var url = "api/admin/user/updateUser";
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that
        .$axiosAdmin({
          method: "post",
          url: url,
          data: JSON.stringify(data),
        })
        .then((res) => {
          console.log("saveMyUserData =>", res);
          that.$alert("保存修改个人资料成功，请重新登录。", "操作成功", {
            confirmButtonText: "确定",
            callback: (action) => {
              that.$router.push({ name: "Login" });
            },
          });
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
  },
};
</script>